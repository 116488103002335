<template>
  <div class="admin-post">
    <h1>Create New Post</h1>
    <form @submit.prevent="submitPost">
      <div class="form-group">
        <label for="commodityKey">Commodity Key:</label>
        <select v-model="post.commodityKey" id="commodityKey" @change="fetchDescriptions" required>
          <option v-for="key in commodityKeys" :key="key" :value="key">{{ key }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="description">Description:</label>
        <select v-model="post.description" id="description" required>
          <option v-for="desc in descriptions" :key="desc" :value="desc">{{ desc }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="category">Category:</label>
        <select v-model="post.category" id="category" required>
          <option v-for="cat in categories" :key="cat" :value="cat">{{ cat }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="country">Country:</label>
        <input type="text" v-model="post.country" id="country" value="Indonesia" readonly />
      </div>

      <div class="form-group">
        <label for="city">City:</label>
        <select v-model="post.city" id="city" required>
          <option v-for="city in cities" :key="city" :value="city">{{ city }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="price">Price:</label>
        <input type="number" v-model="post.price" id="price" required />
      </div>

      <div class="form-group">
        <label for="currency">Currency:</label>
        <input type="text" v-model="post.currency" id="currency" value="IDR" readonly />
      </div>

      <div class="form-group">
        <label for="units">Units:</label>
        <input type="text" v-model="post.units" id="units" value="per MT" readonly />
      </div>

      <div class="form-group">
        <label for="date">Date:</label>
        <input type="date" v-model="post.date" id="date" required />
      </div>

      <button type="submit">Submit Post</button>
      <!-- Conditional rendering for Bulk City Update button -->
      <button v-if="post.city === 'Surabaya'" type="button" @click="bulkCityUpdate">Bulk City Update</button>
      <button type="button" @click="submitDummyData">Submit Dummy Data</button>
    </form>

    <!-- Loading Modal -->
    <div v-if="loading" class="loading-modal">
      <div class="loading-content">
        <p>Loading, please wait...</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      post: {
        commodityKey: '',
        description: '',
        category: '',
        country: 'Indonesia',
        city: '',
        price: null,
        currency: 'IDR',
        units: 'per MT',
        date: '',
      },
      commodityKeys: [],
      categories: [],
      cities: [],
      descriptions: [],
      loading: false, // Loading state
    };
  },
  created() {
    this.fetchCommodityKeys();
    this.fetchCategories();
    this.fetchCities();
  },
  methods: {
    async fetchCommodityKeys() {
      try {
        const response = await fetch('https://api.pota.ai/commodity-keys');
        this.commodityKeys = await response.json();
      } catch (error) {
        console.error('Error fetching commodity keys:', error);
      }
    },
    async fetchCategories() {
      try {
        const response = await fetch('https://api.pota.ai/categories');
        this.categories = await response.json();
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    async fetchCities() {
      try {
        const response = await fetch('https://api.pota.ai/cities');
        this.cities = await response.json();
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    },
    async fetchDescriptions() {
      try {
        const response = await fetch(`https://api.pota.ai/descriptions/${this.post.commodityKey}`);
        this.descriptions = await response.json();
      } catch (error) {
        console.error('Error fetching descriptions:', error);
      }
    },
    async submitPost() {
      try {
        const postData = {
          name: this.post.commodityKey,
          description: this.post.description || "Unknown Description",
          category: this.post.category,
          country: this.post.country,
          city: this.post.city,
          price: this.post.price,
          currency: this.post.currency,
          units: this.post.units,
          date: this.post.date,
        };

        const response = await fetch('https://api.pota.ai/commodities', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Post submitted successfully:', result);
        this.resetForm();
      } catch (error) {
        console.error('Error submitting post:', error);
      }
    },
    async bulkCityUpdate() {
      this.loading = true; // Show loading modal
      const priceAdjustments = {
        Bandung: -300000,
        Lampung: -700000,
        Makassar: -800000,
        Kendari: -1400000,
        'Indragiri Hilir (Penkabaru)': 900000,
        Jakarta: 200000,
        Kalimantan: -600000,
        Manado: -1200000,
        Surabaya: 0,
      };

      const cityPriceUpdates = Object.entries(priceAdjustments).map(([city, adjustment]) => {
        return {
          city,
          price: this.post.price + adjustment,
        };
      });

      for (const { city, price } of cityPriceUpdates) {
        const postData = {
          name: this.post.commodityKey,
          description: this.post.description || "Unknown Description",
          category: this.post.category,
          country: this.post.country,
          city,
          price,
          currency: this.post.currency,
          units: this.post.units,
          date: this.post.date,
        };

        try {
          const response = await fetch('https://api.pota.ai/commodities', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });

          if (!response.ok) {
            throw new Error(`Failed to submit post for ${city}`);
          }

          const result = await response.json();
          console.log(`Post submitted successfully for ${city}:`, result);
        } catch (error) {
          console.error('Error submitting post for city:', city, error);
        }
      }

      this.loading = false; // Hide loading modal
      location.reload(); // Refresh the page
    },
    async submitDummyData() {
      const dummyData = {
        name: 'copra',
        descriptions: 'Copra',
        category: 'coconut',
        country: 'Indonesia',
        city: 'Jakarta',
        price: 16800000,
        currency: 'IDR',
        units: 'MT',
        date: "04/12/2024", // Current date in YYYY-MM-DD format
      };

      try {
        const response = await fetch('https://api.pota.ai/commodities', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dummyData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Dummy data submitted successfully:', result);
      } catch (error) {
        console.error('Error submitting dummy data:', error);
      }
    },
    resetForm() {
      this.post = {
        commodityKey: '',
        description: '',
        category: '',
        country: 'Indonesia',
        city: '',
        price: null,
        currency: 'IDR',
        units: 'per MT',
        date: '',
      };
      this.descriptions = []; // Reset descriptions
    },
  },
};
</script>

<style scoped>
.admin-post {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea,
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

/* Loading Modal Styles */
.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
</style>
