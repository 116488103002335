<template>
  <div class="app">
    <!-- Main Dashboard -->
    <div class="dashboard">
      <!-- Sidebar: List Commodities -->
      <aside class="sidebar" style="text-align: left;">
        <div class="search-bar">
          <input type="text" placeholder="Search symbol" v-model="searchQuery">
        </div>
        <div class="commodities-list">
          <div class="commodity-header">
            <span>Symbol</span>
          </div>
          <div class="commodity-item" v-for="commodity in filteredCommodities" :key="commodity.id">
            <a :href="`/commodity/${commodity.name}`">
              <span>{{ commodity.description }}</span>
              <span>{{ formatCurrency(commodity.bid) }}</span>
              <span>{{ formatCurrency(commodity.ask) }}</span>
            </a>
          </div>
        </div>
      </aside>

      <!-- Main Content Area -->
      <main class="main-content" style="width: 100%; padding-bottom: 200px;">
        <div>
        <h2 style="text-align: left;">
          {{ categoryDescription }} {{ priceTitle }}:
          <span v-if="lastPrice">{{ currencySymbol }} {{ formatCurrency(convertToCurrency(lastPrice)) }} / {{ selectedUnit }}</span>
          {{ selectedCountry }} - {{ selectedCity }}
        </h2>

        <!-- Controls -->
        <div class="controls">
          <div class="currency-selector">
            <label for="currencySelector">Currency: </label>
            <select id="currencySelector" v-model="selectedCurrency" @change="fetchCommodityData">
              <option value="IDR">IDR</option>
              <option value="USD">USD</option>
            </select>
          </div>
          <div class="time-controls">
            <span>Time: </span>
            <button @click="setTimeframe('daily')">1D</button>
            <button @click="setTimeframe('weekly')">1W</button>
            <button @click="setTimeframe('monthly')">1M</button>
            <button @click="setTimeframe('3months')">3M</button>
            <button @click="setTimeframe('6months')">6M</button>
            <button @click="setTimeframe('1year')">1Y</button>
          </div>
          <div class="indicator-controls">
            <span>INDICATOR:</span>
            <button @click="toggleRSI">{{ isRSIVisible ? "Hide RSI" : "Show RSI" }}</button>
          </div>
          <div class="city-selector">
            <label for="citySelector">Filter by City:</label>
            <select id="citySelector" v-model="selectedCity" @change="fetchCommodityData">
              <option v-for="city in availableCities" :key="city" :value="city">
                {{ city }}
              </option>
            </select>
          </div>
        </div>

        <!-- Chart Section -->
        <div id="priceChartContainer" class="chart-container price-chart"></div>
        <div v-if="isRSIVisible" id="rsiChartContainer" class="chart-container rsi-chart"></div>

        <!-- Tooltip for Crosshair -->
        <div id="tooltip" class="tooltip" v-if="tooltipVisible" :style="{ left: tooltipPosition.x + 'px', top: tooltipPosition.y + 'px' }">
          {{ tooltipContent }}
        </div>

        <!-- Buyer and Supplier Tables -->
        <div class="tables-container">
  <div class="buyer-table">
    <h3>Buyer</h3>
    <table class="data-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Type</th>
          <th>Company</th>
           <th>Purchase Quota</th>
           <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(buyer, index) in buyers" :key="index">
          
          <td>{{ index + 1 }}</td>
          <td>{{  buyer.Type }}</td>
          <td>{{ buyer.companyName }}</td>
           <td>{{ buyer.purchaseQuota }}</td>
           <td>{{ formatCurrency(buyer.purchasePrice) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="supplier-table">
    <h3>Supplier</h3>
    <table class="data-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>Type</th>
          <th>Company/Individual</th>
          <th>Selling Quota</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(supplier, index) in suppliers" :key="index">
          
          <td>{{ index + 1 }}</td>
          <td>{{supplier.Type}}</td>
          <td>{{ supplier.companyName }}</td>
          <td>{{ supplier.sellingQuota }}</td>
          <td>{{ formatCurrency(supplier.sellingPrice) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
      </div>
      </main>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { createChart } from "lightweight-charts";

export default {
  data() {
    return {
      prediction: null,
      commodities: [],
      searchQuery: '',
      selectedCommodity: null,
      commodityCategory: this.$route.params.category,
      categoryDescription: '',
      availableCities: [],
      selectedCity: "Jakarta", // Set default city to "Jakarta"
      selectedCountry: "Indonesia",
      selectedUnit: "MT",
      priceChart: null,
      priceSeries: null,
      rsiChart: null,
      rsiSeries: null,
      isRSIVisible: true,
      lastPrice: null,
      selectedTimeframe: 'daily',
      exchangeRate: 1,
      selectedCurrency: 'IDR',
      tooltipVisible: false,
      tooltipContent: '',
      tooltipPosition: { x: 0, y: 0 },
      buyers: [
        { companyName: "Perusahaan A", Type:"A1",purchasePrice: 100000, purchaseQuota: "1000 kg" },
        { companyName: "Perusahaan B", Type:"A1", purchasePrice: 95000, purchaseQuota: "500 kg" },
        { companyName: "Perusahaan C", Type:"A1", purchasePrice: 110000, purchaseQuota: "2000 kg" },
      ],
      suppliers: [
        { companyName: "Supplier A", Type: "A1", sellingPrice: 120000, sellingQuota: "1500 kg" },
        { companyName: "Supplier B", Type: "A1", sellingPrice: 115000, sellingQuota: "800 kg" },
        { companyName: "Supplier C", Type: "A1", sellingPrice: 125000, sellingQuota: "3000 kg" },
      ],
    };
  },
  watch: {
    commodityCategory(newCategory) {
      this.fetchCategoryDescription(newCategory);
    }
  },
  computed: {
    currencySymbol() {
      return this.selectedCurrency === 'USD' ? '$' : 'Rp';
    },
    priceTitle() {
      const titles = {
        daily: 'Price Today',
        weekly: 'Price This Week',
        monthly: 'Price This Month',
        '3months': 'Price Last 3 Months',
        '6months': 'Price Last 6 Months',
        '1year': 'Price This Year',
      };
      return titles[this.selectedTimeframe] || 'Price Today';
    },
    filteredCommodities() {
      return this.commodities.filter(commodity => 
        commodity.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  methods: {
    fetchCategoryDescription(category) {
      axios.get(`https://api.pota.ai/commodities/${category}/description`)
        .then(response => {
          this.categoryDescription = response.data.description;
        })
        .catch(error => {
          console.error('Error fetching category description:', error);
          this.categoryDescription = 'Description not available';
        });
    },
    fetchPrediction() {
      axios.get(`https://api.pota.ai/predict-arima/${this.commodityCategory}?city=${this.selectedCity}`)
        .then(response => {
          this.prediction = response.data.prediction;
        })
        .catch(error => {
          console.error('Error fetching prediction:', error);
        });
    },
    fetchCommodities() {
      axios.get('https://api.pota.ai/commodities')
        .then(response => {
          this.commodities = response.data;
        })
        .catch(error => console.error("Failed to fetch commodities:", error));
    },
    fetchCities() {
      const url = `https://api.pota.ai/commodities/${this.commodityCategory}/cities`;
      axios.get(url)
        .then(response => {
          this.availableCities = response.data;
          // Check if "Jakarta" is in the available cities
          if (!this.availableCities.includes(this.selectedCity)) {
            this.selectedCity = this.availableCities.length > 0 ? this.availableCities[0] : ""; // Set to first available city if Jakarta is not found
          }
          this.fetchCommodityData();
        })
        .catch(error => {
          console.error("Failed to fetch cities:", error);
        });
    },
    setTimeframe(timeframe) {
      this.selectedTimeframe = timeframe;
      this.fetchCommodityData();
    },
    fetchCommodityData() {
      const url = `https://api.pota.ai/commodities/${this.commodityCategory}?city=${this.selectedCity}&timeframe=${this.selectedTimeframe}`;
      axios.get(url)
        .then(response => {
          const prices = response.data
            .filter(item => !isNaN(new Date(item.date)) && typeof item.price === "number")
            .map(item => ({
              time: new Date(item.date).toISOString().split("T")[0],
              value: item.price,
            }));

          prices.sort((a, b) => new Date(a.time) - new Date(b.time));
          this.lastPrice = prices.length > 0 ? prices[prices.length - 1].value : null;

          this.priceSeries.setData(prices);
          this.priceChart.timeScale().fitContent();

          if (this.isRSIVisible) {
            if (prices.length >= 14) {
              const rsiValues = this.calculateRSI(prices.map(p => p.value), 14);
              const rsiData = rsiValues
                .map((value, index) => {
                  const time = prices[index + 30]?.time;
                  return time && value !== null && !isNaN(value) ? { time, value } : null;
                })
                .filter(point => point !== null);

              this.rsiSeries.setData(rsiData);
              this.rsiChart.timeScale().fitContent();
            } else {
              console.warn("Not enough data to calculate RSI");
            }
          }
        })
        .catch(error => {
          console.error("Failed to fetch data:", error);
        });
    },
    setupCharts() {
      const priceChartContainer = document.getElementById("priceChartContainer");
      this.priceChart = createChart(priceChartContainer, {
        width: priceChartContainer.offsetWidth,
        height: 400,
        layout: {
          backgroundColor: "#FFFFFF",
          textColor: "#000000",
        },
        grid: {
          vertLines: { color: "#f0f0f0" },
          horzLines: { color: "#f0f0f0" },
        },
        timeScale: {
          fixLeftEdge: true,
          rightOffset: 0,
          borderVisible: false,
        },
      });

      this.priceSeries = this.priceChart.addLineSeries({
        color: "#2196F3",
        lineWidth: 2,
      });

      if (this.isRSIVisible) {
        this.setupRSIChart();
      }

      window.addEventListener("resize", () => {
        this.priceChart.applyOptions({ width: priceChartContainer.offsetWidth });
        if (this.isRSIVisible) {
          this.rsiChart.applyOptions({ width: document.getElementById("rsiChartContainer").offsetWidth });
        }
      });

      // Crosshair synchronization
      this.setupCrosshairSync();
    },
    setupRSIChart() {
      const rsiChartContainer = document.getElementById("rsiChartContainer");
      this.rsiChart = createChart(rsiChartContainer, {
        width: rsiChartContainer.offsetWidth,
        height: 200,
        layout: {
          backgroundColor: "#FFFFFF",
          textColor: "#000000",
        },
        grid: {
          vertLines: { color: "#f0f0f0" },
          horzLines: { color: "#f0f0f0" },
        },
        timeScale: {
          fixLeftEdge: true,
          rightOffset: 0,
          borderVisible: false,
        },
      });

      this.rsiSeries = this.rsiChart.addLineSeries({
        color: "#FF9800",
        lineWidth: 2,
      });

      const rsiLevels = [
        { value: 70, color: "red" },
        { value: 50, color: "gray" },
        { value: 30, color: "green" },
      ];

      rsiLevels.forEach(level => {
        const levelLine = this.rsiChart.addLineSeries({
          color: level.color,
          lineWidth: 1,
          lineStyle: 2,
        });
        levelLine.setData([
          { time: "2000-01-01", value: level.value },
          { time: "2100-01-01", value: level.value },
        ]);
      });
    },
    toggleRSI() {
      this.isRSIVisible = !this.isRSIVisible;
      if (this.isRSIVisible) {
        this.$nextTick(() => {
          this.setupRSIChart();
          this.fetchCommodityData();
        });
      }
    },
    calculateRSI(prices, period) {
      const gains = [];
      const losses = [];

      for (let i = 1; i < prices.length; i++) {
        const change = prices[i] - prices[i - 1];
        gains.push(change > 0 ? change : 0);
        losses.push(change < 0 ? Math.abs(change) : 0);
      }

      const avgGain = Array(prices.length).fill(0);
      const avgLoss = Array(prices.length).fill(0);
      for (let i = period; i < prices.length; i++) {
        const sliceGains = gains.slice(i - period, i);
        const sliceLosses = losses.slice(i - period, i);
        avgGain[i] = sliceGains.reduce((a, b) => a + b, 0) / period;
        avgLoss[i] = sliceLosses.reduce((a, b) => a + b, 0) / period;
      }

      return avgGain.map((gain, i) => {
        const loss = avgLoss[i];
        if (i < period || loss === 0) return null;
        const rs = gain / loss;
        return 100 - 100 / (1 + rs);
      }).filter(val => val !== null);
    },
    formatCurrency(value) {
      if (isNaN(value)) return value;
      const numberString = Math.floor(value).toString();
      const formattedInteger = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      const groups = formattedInteger.split('.');
      return groups.length > 3 ? groups.slice(-3).join('.') : formattedInteger;
    },
    fetchExchangeRate() {
      axios.get('https://api.exchangerate-api.com/v4/latest/IDR')
        .then(response => {
          this.exchangeRate = response.data.rates.USD;
        })
        .catch(error => {
          console.error('Error fetching exchange rate:', error);
        });
    },
    convertToCurrency(amount) {
      return this.selectedCurrency === 'USD' ? (amount * this.exchangeRate).toFixed(2) : amount;
    },
    setupCrosshairSync() {
      const chart1 = this.priceChart;
      const chart2 = this.rsiChart; // Assuming you want to sync with RSI chart

      chart1.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        chart2.timeScale().setVisibleLogicalRange(timeRange);
      });

      chart2.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        chart1.timeScale().setVisibleLogicalRange(timeRange);
      });

      const getCrosshairDataPoint = (series, param) => {
        if (!param.time) {
          return null;
        }
        const dataPoint = param.seriesData.get(series);
        return dataPoint || null;
      };

      const syncCrosshair = (chart, series, dataPoint) => {
        if (dataPoint) {
          chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
          this.showTooltip(chart, dataPoint); // Ensure 'this' refers to the Vue instance
          return;
        }
        chart.clearCrosshairPosition();
        this.hideTooltip(); // Ensure 'this' refers to the Vue instance
      };

      chart1.subscribeCrosshairMove(param => {
        const dataPoint = getCrosshairDataPoint(this.priceSeries, param);
        syncCrosshair(chart2, this.rsiSeries, dataPoint);
      });

      chart2.subscribeCrosshairMove(param => {
        const dataPoint = getCrosshairDataPoint(this.rsiSeries, param);
        syncCrosshair(chart1, this.priceSeries, dataPoint);
      });
    },
    showTooltip(chart, dataPoint) {
      this.tooltipVisible = true;
      this.tooltipContent = `Value: ${dataPoint.value}<br>Time: ${dataPoint.time}`;
      const rect = chart.getBoundingClientRect();
      this.tooltipPosition.x = rect.left + window.scrollX + 10; // Offset for tooltip
      this.tooltipPosition.y = rect.top + window.scrollY + 10; // Offset for tooltip
    },
    hideTooltip() {
      this.tooltipVisible = false;
    }
  },
  mounted() {
    this.fetchCategoryDescription(this.commodityCategory);
    this.setupCharts();
    this.fetchCities();
    this.fetchCommodities();
    this.fetchExchangeRate();
  },
};
</script>

<style scoped>
.commodity-item a {
  color: black;
  text-decoration: none;
}
.commodity-item a:hover {
  text-decoration: underline;
  color: grey;
}

.sidebar {
  background-color: #f4f4f4;
  padding: 20px;
  width: 200px;
  overflow: auto;
}

.search-bar input {
  width: 90%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.commodities-list {
  background-color: #fff;
  border: 1px solid #ddd;
}

.commodity-header, .commodity-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.commodity-header {
  background-color: #e9e9e9;
  font-weight: bold;
}

.commodity-item:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

/* Dashboard Layout */
.dashboard {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: auto 1fr;
  gap: 10px;
  height: calc(100vh - 50px);
  font-family: Arial, sans-serif;
}

/* Chart Containers */
.chart-container {
  margin-top: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  min-width: 150px; /* Set minimum width for Y-axis */
  width: 100%; /* Make it responsive */
}

.price-chart {
  height: 50vh;
}

.rsi-chart {
  height: 25vh;
}

/* Controls Layout */
.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.time-controls button,
.indicator-controls button {
  margin-right: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.time-controls button:hover,
.indicator-controls button:hover {
  background-color: #2196f3;
  color: #fff;
}

.city-selector label,
.currency-selector label {
  font-size: 14px;
  margin-right: 5px;
}

.city-selector select,
.currency-selector select {
  padding: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
}

/* Tooltip Styles */
.tooltip {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  z-index: 10;
  pointer-events: none; /* Prevent mouse events on tooltip */
}

/* Data Table Styles */
.tables-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.supplier-table, .buyer-table {
  width: 48%; /* Adjust width as needed */
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th, .data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}
</style>
