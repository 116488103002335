<template>
  <div>
    <div class="jumbotron">
      <h1>Welcome to POTA AI</h1>
      <p class="lead">Empowering businesses with real-time insights and data-driven forecasts in the commodity market.</p>
    </div>
    <h1>Market Overview</h1>
    <table v-if="commodities.length">
      <thead>
        <tr>
          <th>Commodity</th>
          <th>Country</th> <!-- New column for country -->
          <th>1 Week</th>
          <th>1 Month</th>
          <th>3 Months</th>
          <th>5 Months</th>
          <th>1 Year</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="commodity in commodities" :key="commodity.name">
          <td style="text-align: left;">
            <span @click="navigateToCommodity(commodity.name)" class="commodity-link">{{ commodity.description }}</span>
          </td>
          <td>Indonesia</td> <!-- Data binding for country -->
          <td :class="getClass(commodity.oneWeek)">{{ commodity.oneWeek }}</td>
          <td :class="getClass(commodity.oneMonth)">{{ commodity.oneMonth }}</td>
          <td :class="getClass(commodity.threeMonths)">{{ commodity.threeMonths }}</td>
          <td :class="getClass(commodity.fiveMonths)">{{ commodity.fiveMonths }}</td>
          <td :class="getClass(commodity.oneYear)">{{ commodity.oneYear }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      Loading data or data unavailable...
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const commodities = ref([]);

const getClass = (value) => {
  if (!value || typeof value !== 'string') return 'no-change';
  return value === "0.00%" ? 'no-change' : (value.startsWith('+') ? 'positive' : 'negative');
};

const fetchCommodities = async () => {
  try {
    const response = await fetch('https://api.pota.ai/commodities');
    const data = await response.json();
    commodities.value = data;
    commodities.value.forEach(commodity => {
      fetchPriceData(commodity.name, 'Jakarta'); // Pass the city as an argument
    });
  } catch (error) {
    console.error('Failed to fetch commodities:', error);
  }
};

const fetchPriceData = async (commodityName, city) => {
  try {
    const response = await fetch(`https://api.pota.ai/commodities/${commodityName}?city=${city}`);
    const prices = await response.json();
    prices.sort((a, b) => new Date(b.date) - new Date(a.date));

    if (prices.length >= 12) {
      const formatPriceChange = (index1, index2) => {
        const priceNew = prices[index1].price;
        const priceOld = prices[index2].price;
        return priceNew === priceOld ? "0.00%" :
          `${priceNew - priceOld >= 0 ? '+' : ''}${((priceNew - priceOld) / priceOld * 100).toFixed(2)}%`;
      };

      const index = commodities.value.findIndex(com => com.name === commodityName);
      if (index !== -1) {
        commodities.value[index].country = prices[0].country; // Assuming country is returned here
        commodities.value[index].oneWeek = formatPriceChange(0, 7);
        commodities.value[index].oneMonth = formatPriceChange(0, 30);
        commodities.value[index].threeMonths = formatPriceChange(0, 90);
        commodities.value[index].fiveMonths = formatPriceChange(0, 150);
        commodities.value[index].oneYear = formatPriceChange(0, 360);
      }
    }
  } catch (error) {
    console.error(`Failed to fetch prices for ${commodityName}:`, error);
  }
};

const navigateToCommodity = (commodityName) => {
  router.push({ path: `/commodity/${commodityName}` }); // Use router.push to navigate
};

onMounted(() => {
  fetchCommodities();
});
</script>

<style scoped>
.jumbotron {
  background: #333333; /* Replace with your own image URL */
  background-size: cover;
  color: black;
  padding: 50px 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.19), 0 8px 17px rgba(0, 0, 0, 0.2);
}

.jumbotron h1 {
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.jumbotron .lead {
  font-size: 1.5rem;
  font-weight: 300;
  color: white;
}
h1 {
  text-align: center;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: right;
}
th {
  background-color: #f4f4f4;
}
.commodity-link {
  cursor: pointer; /* Change cursor to pointer for clickable text */
  color: inherit; /* Use the text color of the parent */
}
.positive {
  color: green; /* Positive values in green */
}
.negative {
  color: red; /* Negative values in red */
}
.no-change {
  color: black; /* No change values in black */
}
</style>